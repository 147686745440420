const SwapStockRecord = Immutable.Record({
  planningID: null,
  oldStockID: null,
  newStockID: null,
  confirmed: false,
  inProgress: false,
  failed: false,
  error: null,
  checkingMargin: false,
  currentMargin: null,
  customerMargin: null,
  customerID: null,
  marginExceedPercentage: 0,
});

const record = Immutable.Record({
  loading: false,
  center: null,
  page: 1,
  per_page: 50,
  lastPage: false,
  zoom: 'months',
  from: moment().startOf('month'),
  till: moment().startOf('month').add(1, 'month').endOf('month'),
  stockIDs: Immutable.List(),
  autocompleteIds: [],
  cartAccessoriesIds: [],
  autocompleteQuery: '',
  query: '',
  filters: {
    active: true,
    book: true,
    product_id: '',
    depot_id: 'rental',
    domain_id: '',
  },
  product_type: '',
  swapStock: new SwapStockRecord(),
});

const initialState = new record();

function CalendarReducer(state = initialState, action) {
  switch (action.type) {
    case 'CALENDAR_INITIALIZE':
      return initialState;

    case 'CALENDAR_SET_PERIOD': {
      let from = moment(action.payload.from).startOf('month');
      let till = moment(Math.max(moment(action.payload.till), from.clone().add(1, 'month'))).endOf('month');
      return state.merge({ from, till });
    }

    case 'CALENDAR_CENTER_ON_DATE':
      return state.set('center', moment(action.payload.date));

    case 'CALENDAR_SET_ZOOM': {
      state = state.merge({ zoom: action.payload.zoom });
      const from = state.from.clone().startOf(action.payload.zoom);
      const till = state.till.clone().endOf(action.payload.zoom);
      return state.merge({ from, till });
    }

    case 'CALENDAR_SET_AUTOCOMPLETE_RESULTS':
      return state
        .set('autocompleteIds', action.payload.autocompleteIds)
        .set('cartAccessoriesIds', action.payload.cartAccessoriesIds);

    case 'CALENDAR_SET_AUTOCOMPLETE_QUERY':
      return state.set('autocompleteQuery', action.payload.autocompleteQuery);

    case 'CALENDAR_SET_FILTERS':
      return state
        .set('page', 1)
        .set('lastPage', false)
        .set('query', action.payload.query)
        .set('filters', action.payload.filters)
        .set('product_type', action.payload.product_type)
        .set('stockIDs', Immutable.List());

    case 'CALENDAR_MORE_STOCK':
      return !state.loading ? state.set('page', state.page + 1) : state;

    case 'CALENDAR_ASK_SWAP': {
      const swapStock = state.swapStock.merge({
        planningID: action.payload.planningID,
        oldStockID: action.payload.oldStockID,
        newStockID: action.payload.newStockID,
        confirmed: false,
      });

      return state.set('swapStock', swapStock);
    }

    case 'CALENDAR_FETCH_ORDER_MARGIN_START':
      return state.setIn(['swapStock', 'checkingMargin'], true);

    case 'CALENDAR_FETCH_ORDER_MARGIN_SUCCESS': {
      const swapStock = state.swapStock.merge({
        checkingMargin: false,
        currentMargin: action.payload.data.current_margin,
        customerMargin: action.payload.data.customer_margin,
        customerID: action.payload.data.customer_id,
        marginExceedPercentage: action.payload.data.margin_percentage,
      });

      return state.set('swapStock', swapStock);
    }

    case 'CALENDAR_CANCEL_SWAP':
      return state.set('swapStock', new SwapStockRecord());

    case 'Model.StockPlanning.actionTypes.SWAP_START':
      return state
        .setIn(['swapStock', 'inProgress'], true)
        .setIn(['swapStock', 'failed'], false);

    case 'Model.StockPlanning.actionTypes.SWAP_ERROR': {
      const data = action.payload.error;
      const error = {
        code: data.error_code,
        message: data.error_message,
        data: data.error_data,
      };

      return state
        .setIn(['swapStock', 'failed'], true)
        .setIn(['swapStock', 'error'], error);
    }

    case 'Model.StockPlanning.actionTypes.SWAP_SUCCESS':
      return state.set('swapStock', new SwapStockRecord());

    case 'CALENDAR_FETCH_STOCK_DATA_START':
      return state.set('loading', true);

    case 'CALENDAR_FETCH_STOCK_DATA_SUCCESS':
      return state
        .set('lastPage', action.payload.lastPage)
        .set('loading', false)
        .set('stockIDs', state.stockIDs.concat(action.payload.normalizedResponse.result.stocks));

    default:
      return state;
  }
}

module.exports = CalendarReducer;
