import React, { memo, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import ReactSelect from 'react-select'
import _ from 'lodash/fp'

import NoSale from 'client/react/scenes/calendar/planner/no_sale'
import Monthpicker from 'client/react/components/inputs/monthpicker'
import Checkbox from 'client/react/components/inputs/checkbox'
import CalendarActions from 'client/redux/actions/calendar_actions'
import productActions from 'client/redux/actions/product'
import NoSaleActions from 'client/redux/actions/no_sale'

import { selectOrderById, selectProductsForIds } from 'mobile/redux/selectors/ormSelectors'

const Actions = memo(({
  domains,
  depots
}) => {
  const calendar = useSelector(state => state.calendar)
  const planner = useSelector(state => state.planner)
  const order = useSelector(state => selectOrderById(state, planner.orderID))
  const autocompleteResults = useSelector(state => selectProductsForIds(state, calendar.autocompleteIds))

  const selectOptions = () => {
    const options = []
    depots.forEach((group) => {
      if (group.children) {
        options.push({
          label: group.label,
          options: group.children
        })
      } else {
        options.push(group)
      }
    })
    return options
  }

  const getSelectedDepot = () => {
    const depotId = calendar.filters.depot_id

    for (const depot of depots) {
      if (depot.value === depotId) {
        return depot
      }
      if (depot.children) {
        for (const child of depot.children) {
          if (child.value === depotId) {
            return child
          }
        }
      }
    }
  }

  const selectedDomain = useMemo(() => {
    const domain = _.find({ value: calendar.filters.domain_id }, domains)
    return { label: domain?.label }
  }, [domains, calendar.filters.domain_id])

  const getAutocompleteOptions = () => {
    if (autocompleteResults.size() === 0) {
      return []
    }

    return autocompleteResults.map((option) => ({
      value: option.id,
      label: option.name
    }))
  }

  const getQuery = () => {
    if (!calendar.autocompleteQuery) {
      return null
    }

    return { label: calendar.autocompleteQuery }
  }

  const getProductType = useCallback(() => {
    if (!order) {
      return "all"
    }

    if (order.handling_type === 'subscription') {
      return "subscription"
    } else {
      return "rental"
    }
  }, [order])

  const getCalendarData = useCallback(() => ({
    query: calendar.query,
    filters: calendar.filters,
    product_type: getProductType(),
  }), [calendar.query, calendar.filters, getProductType])

  const autocomplete = useCallback((value) => {
    if (_.isEmpty(value)) return

    CalendarActions.setAutocompleteQuery(value)

    productActions.autocomplete({ query: value, product_type: getProductType() }).then((response) => {
      const productIds = value === '' ? [] : response.payload.normalizedResponse.result.products
      CalendarActions.setAutocompleteResults(productIds)
    })
  }, [getProductType])

  const selectAutocomplete = useCallback((item) => {
    NoSaleActions.unsetNoSale()

    const query = item?.label
    const calendarData = getCalendarData()

    calendarData.query = query
    calendarData.filters.product_id = item?.value

    CalendarActions.setFilters(calendarData)
    CalendarActions.setAutocompleteQuery(query)
  }, [getCalendarData])

  const changeDepotId = useCallback((value) => {
    const calendarData = getCalendarData()
    calendarData.filters.depot_id = value ? value.value : 'rental'

    CalendarActions.setFilters(calendarData)
  }, [getCalendarData])

  const changeDomainId = useCallback((value) => {
    const calendarData = getCalendarData()
    if (value) {
      calendarData.filters.domain_id = value.value
    }

    CalendarActions.setFilters(calendarData)
  }, [getCalendarData])

  const setActive = useCallback((value) => {
    const calendarData = getCalendarData()
    calendarData.filters.active = !value
    CalendarActions.setFilters(calendarData)
  }, [getCalendarData])

  const setBook = useCallback((value) => {
    const calendarData = getCalendarData()
    calendarData.filters.book = !value
    CalendarActions.setFilters(calendarData)
  }, [getCalendarData])

  const setPeriod = useCallback((date) => {
    CalendarActions.setMonth(date)
  }, [])

  const handleKeyDown = useCallback((event) => {
    if (event.key !== 'Enter') {
      return
    }

    selectAutocomplete({ label: event.target.value })
  }, [selectAutocomplete])

  return (
    <>
      <div className="filter">
        <ReactSelect
          className="react-select-container"
          options={selectOptions()}
          value={getSelectedDepot()}
          placeholder={I18n.t('javascripts.planner.select_depot')}
          onChange={changeDepotId}
        />
      </div>
      <div className="filter">
        <ReactSelect
          className="react-select-container"
          options={domains}
          value={selectedDomain}
          placeholder={I18n.t('javascripts.planner.select_domain')}
          onChange={changeDomainId}
        />
      </div>
      <div className="filter">
        <ReactSelect
          inputId="search-product"
          className="react-select-container react-select-search-product"
          options={getAutocompleteOptions()}
          value={getQuery()}
          onChange={selectAutocomplete}
          onInputChange={autocomplete}
          onKeyDown={handleKeyDown}
          placeholder={I18n.t('javascripts.planner.search_product')}
          isClearable
        />
      </div>

      {
        order
          ? (
            <NoSale />
            )
          : (
            <>
              <Monthpicker onChange={setPeriod} />
              <span className="filter">
                <Checkbox
                  label={I18n.t('javascripts.planner.show_inactive_products')}
                  onChange={setActive}
                />
              </span>
              <span className="filter">
                <Checkbox
                  label={I18n.t('javascripts.planner.show_only_non_rental_products')}
                  onChange={setBook}
                />
              </span>
            </>
            )
      }
    </>
  )
})

Actions.displayName = 'Actions'

export default Actions
