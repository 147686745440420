Model.Product.createStrategy('autocomplete', (model, options) => {
  const params = {
    q: options.query, // eslint-disable-line id-length
    product_type: options.product_type,
    per_page: 15,
    show_in_planner: true,
  }

  return API.getCollection('products/autocomplete', params)
});

module.exports = Model.Product.actions;
